import React, { FC, useState, useEffect, useRef } from 'react'
import { useTimer } from '../hooks/useTimer'
import VideoDrawModal from '../templates/VideoDrawModal'
import { WebViewVideoAction, WebViewVideoActionType } from '../types/WebViewVideoAction'


const ENVIRONMENT = process.env.ENVIRONMENT

type Props = {
}

const VideoDrawModalPage: FC<Props> = ( { } ) => {

    const [ modelVisible, setModalVisible ] = useState( false );
    const videoRef = useRef<HTMLVideoElement>( null )
    useEffect( () => {
        fixBackground()
    }, [] )

    useEffect( () => {
        videoRef.current && setModalVisible( true )
    }, [ videoRef ] )

    const fixBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflow = "hidden"
        document.getElementsByTagName( "body" )[ 0 ].style.overflow = "hidden"
    }

    const webViewDispatch = ( type: WebViewVideoActionType ) => {
        let data
        switch ( type ) {
            case WebViewVideoAction.START_SEEK:
                data = { event: WebViewVideoAction.START_SEEK }
                break
            case WebViewVideoAction.END_SEEK:
                data = { event: WebViewVideoAction.END_SEEK }
                break
            case WebViewVideoAction.START_DRAW:
                const canvasBackGround = document.getElementById( "canvas_background" )
                data = {
                    event: WebViewVideoAction.START_DRAW,
                    height: String( canvasBackGround ?
                        canvasBackGround.getBoundingClientRect().height
                        : 300 )
                }
                break
            case WebViewVideoAction.END_DRAW:
                data = { event: WebViewVideoAction.END_DRAW }
                break
            case WebViewVideoAction.ADD_IMAGE_TO_COMMENT:
                data = {
                    event: WebViewVideoAction.ADD_IMAGE_TO_COMMENT,
                    image: window.commentFormFile,
                    srcURL: window.canvasImageURL
                }
                break
            case WebViewVideoAction.DOWNLOAD_CANVAS_IMAGE:
                data = {
                    event: WebViewVideoAction.DOWNLOAD_CANVAS_IMAGE,
                    image: window.canvasImageFile,
                    srcURL: window.canvasImageURL
                }
                break
        }
        try {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage( JSON.stringify( data ) );
        }
        catch ( error ) {
            alert( error )
        }
    }

    return (
        <div>
            <video ref={ videoRef } />
            { modelVisible && videoRef.current &&
                <VideoDrawModal
                    video={ videoRef.current }
                    videoScale={ 1 }
                    closeModal={ () => { } }
                    forWebView={ true }
                    webViewDispatch={ webViewDispatch } /> }
        </div>
    )
}
export default VideoDrawModalPage
